/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */


$colors: (
        primary: #488aff,
        secondary: #32db64,
        danger: #FF0000,
        light: #f4f4f4,
        dark: #222222,
        unique: #DAA520,
        rune: #FF8C00,
        black: black,
);


/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'Rubik';
  --ion-text-color: white, rgb(255, 255, 255);
  --ion-text-color-rgb: rgb(255, 255, 255);

  /** primary **/
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;

  /** secondary **/
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  --unique: #A59263;
  --rune: #FF8C00;
  --base: #999797;
  --set: #00ff3c;
  --magic: #7d80e6;

  --border-color: #9d9fa6;
  --muted: #979797;

  --background: #222 !important;
  --ion-background-color: #222 !important;

  --topaz: #ffc87c;
  --amethyst: #9966cc;
  --diamond: #eeead3;
  --ruby: #f53030;
  --emerald: #32db64;
  --sapphire: #488aff;
  --skull: #cec8bf;

  --filter-base: brightness(0) saturate(100%) invert(56%) sepia(57%) saturate(0%) hue-rotate(228deg) brightness(95%) contrast(94%);
}

.ios {
  --ion-text-color: white, rgb(255, 255, 255);
  --ion-text-color-rgb: rgb(255, 255, 255);
}

.md {
  --ion-text-color: white, rgb(255, 255, 255);
  --ion-text-color-rgb: rgb(255, 255, 255);
}