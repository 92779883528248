@import '@ionic/react/css/core.css';
@import '@ionic/react/css/normalize.css';
@import '@ionic/react/css/structure.css';
@import '@ionic/react/css/typography.css';

@import '@ionic/react/css/padding.css';
@import '@ionic/react/css/float-elements.css';
@import '@ionic/react/css/text-alignment.css';
@import '@ionic/react/css/text-transformation.css';
@import '@ionic/react/css/flex-utils.css';
@import '@ionic/react/css/display.css';

@font-face {
  font-family: 'Rubik';
  src: url("./fonts/Rubik-VariableFont_wght.ttf") format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Diablo';
  src: url("./fonts/DiabloHeavy_fixed.woff") format('woff');
  font-weight: 400;
  font-style: normal;
}


html, body {
  font-family: 'Rubik', sans-serif !important;
}

ion-content {
  background-color: black;
}

ion-modal {
  //--height: auto;
}

ion-modal.window {
  --width: fit-content;
  --min-width: 250px;
  --max-width: 90%;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
}

.ion-color.sc-ion-searchbar-ios-h {
  background: #222 !important;
}

.rune, .runeword {
  color: var(--rune) !important;
}

.white, .other {
  color: white !important;
}

.unique {
  color: var(--unique) !important;
}

.rare {
  color: yellow !important;
}

.warning {
  color: red !important;
  font-size: smaller;
}

.rejuv {
  color: #9966cc !important;
}

.magic {
  color: #7d80e6 !important;
}

.set {
  color: var(--set) !important;
}

.base {
  color: var(--base) !important;
}

.base_armor {
  color: var(--base) !important;
}

.base_weapon {
  color: var(--base) !important;
}

.muted {
  color: var(--muted) !important;
}

.l {
  color: #ffff00;
}

.f {
  color: #ff2600;
}

.p {
  color: #31d731;
}

.c {
  color: #5656e8;
}

.m {
  color: #ffa200;
}

.ph {
  color: #957522;
}

.misc {
  color: #d95b5b !important;
}

.topaz, .Topaz {
  color: var(--topaz);
}

.amethyst, .Amethyst {
  color: var(--amethyst)
}

.diamond, .Diamond {
  color: var(--diamond);
}

.ruby, .Ruby {
  color: var(--ruby);
}

.emerald, .Emerald {
  color: var(--emerald);
}

.sapphire, .Sapphire {
  color: var(--sapphire);
}

.skull, .Skull {
  color: var(--skull);
}

.f-14 {
  font-size: 14px;
}

.rainbow {
  background: red; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left, #FAEBD7FF, red, #ffc87c, #32db64, #488aff, #BF83F1FF); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(right, #FAEBD7FF, red, #ffc87c, #32db64, #488aff, #BF83F1FF); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(right, #FAEBD7FF, red, #ffc87c, #32db64, #488aff, #BF83F1FF); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to right, #FAEBD7FF, red, #ffc87c, #32db64, #488aff, #BF83F1FF); /* Standard syntax (must be last) */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

ion-input.end input {
  text-align: end !important;
  padding-right: 4px !important;
}

ion-input.slim, ion-select.slim {
  min-height: 30px !important;
  height: 30px !important;
}

ion-tab-button {
  --background: black;
}

ion-item::part(native) {
  background: #222;
  color: white;
}

ion-list {
  background: #222 !important;
}

.ion-accordion-toggle-icon {
  color: white;
}

.arrow:after {
  content: '\21d2';
  color: white;
}

ion-segment-button {
  --color: white !important;
}

.alert-head {
  color: var(--ion-color-primary);
}

.alert-message {
  white-space: pre-wrap;
}

.alert-input {
  color: black;
  --ion-background-color: white;
}

ion-checkbox {
  --border-color: white !important;
}

ion-textarea {
  --background: #8b8888 !important;
}

ion-list-header.no-inset {
  padding-left: 0 !important;
}

ion-list-header.white {
  color: white;
}

.alert-radio-label {
  &.sc-ion-alert-md {
    color: white;
  }

  &.sc-ion-alert-ios {
    color: black;
  }
}

ion-modal.auto-height {
  --height: auto;
}

button[aria-checked="true"] > div > div.alert-radio-label.sc-ion-alert-md {
  color: var(--ion-color-primary) !important;
}

.settings-accordion {
  border: 1px solid white;
}

ion-list-header {
  font-size: 20px;
}

ion-label, label {
  color: white !important;

  --color: white !important;

  &.disabled {
    color: var(--muted) !important;
  }
}

ion-toggle {
  --track-background: rgba(225, 45, 45, 0.7);
}

.action-sheet-button {
  color: white !important;
}

// --- HTML DIALOG START --- //
dialog {
  outline: none;
  border-radius: 16px;
  padding: 0;
  opacity: 0;
  background-color: var(--background);
  border: 1px solid #b9b3b3b0;
  box-shadow: #b9b3b3b0 4px 3px 20px 11px;
}

dialog::backdrop {
  background-color: black;
  opacity: 0.7;
}

dialog[open].no-animate {
  opacity: 1;
}

dialog[open].allow-overflow {
  overflow: visible;
}

dialog[open]:not(.no-animate) {
  opacity: 1;
  animation: modal-enter 0.3s forwards;
}

@keyframes modal-enter {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  99% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: unset;
  }
}

// --- HTML DIALOG END --- //

.swiper.dialog-swiper {
  max-width: 90vw;
}

.monster-popover ion-select-popover ion-item {
  --background: #45fe32;
  --color: #4550f5;
  --inner-border-width: 0;
}

.monster-popover ion-select-popover ion-radio {
  --color: #a61134 !important;
  --color-checked: #4550f5;
}

.ios-safe-area {
  margin-top: env(safe-area-inset-top);
}

.restore-result {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  > div {
    color: white;
    margin-bottom: 8px;
  }

  > div > span {
    color: steelblue;
  }

  > h3 {
    font-size: 16px;
    margin: 0 0 2px;
  }
}